<template>
    <div>
        <div
            style="
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 22px;
                color: red;
            "
        >
            <p>
                <span>Sử dụng bằng cách copy thẻ bên dưới</span>
            </p>
        </div>

        <el-row :gutter="20">
            <el-col>
                <p class="body-small-semi-bold">Mặc định / Có Icon</p>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input placeholder="Please input" v-model="input"></el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input placeholder="Please input" v-model="input">
                    <span
                        slot="prefix"
                        class="icon-search el-input__icon"
                    ></span>
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input placeholder="Please input" v-model="input"></el-input>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input placeholder="Please input" v-model="input">
                    <span
                        slot="suffix"
                        class="icon-search el-input__icon"
                    ></span>
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input placeholder="Please input" v-model="input">
                    <span slot="suffix" class="el-input__icon">
                        <span class="body-small-regular neutral-600"
                            >Subtext</span
                        >
                    </span>
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input disabled placeholder="Please input" v-model="input">
                    <span slot="suffix" class="el-input__icon">
                        <span class="body-small-regular neutral-600"
                            >Subtext</span
                        >
                    </span>
                </el-input>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-input
                    disabled
                    placeholder="Please input"
                    v-model="textFilled"
                >
                    <span slot="suffix" class="el-input__icon">
                        <span class="body-small-regular neutral-600"
                            >Subtext</span
                        >
                    </span>
                </el-input>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item prop="name">
                        <el-input
                            placeholder="Please input"
                            v-model="input"
                        ></el-input>
                    </el-form-item>

                    <el-form-item prop="name">
                        <el-input placeholder="Please input" v-model="input">
                            <span
                                slot="prefix"
                                class="icon-search el-input__icon"
                            ></span>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Label name" prop="name">
                        <el-input placeholder="Please input" v-model="input">
                            <span
                                slot="prefix"
                                class="icon-search el-input__icon"
                            ></span>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Label name" prop="name">
                        <el-input placeholder="Please input" v-model="input">
                            <span
                                slot="suffix"
                                class="icon-search el-input__icon"
                            ></span>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Label name" prop="name">
                        <el-input placeholder="Please input" v-model="input">
                            <span slot="suffix" class="el-input__icon">
                                <span class="body-small-regular neutral-600"
                                    >Subtext</span
                                >
                            </span>
                        </el-input>
                    </el-form-item>

                    <el-form-item prop="name">
                        <template #label>
                            <div class="flex-center">
                                <p>Validate ToolTip</p>
                                <el-tooltip
                                    class="color-primary-600"
                                    effect="dark"
                                    content="Bottom Left prompts info"
                                    placement="bottom-start"
                                >
                                    <span class="icon-error"></span>
                                </el-tooltip>
                            </div>
                        </template>

                        <el-input
                            disabled
                            placeholder="Please input"
                            v-model="input"
                        >
                            <span slot="suffix" class="el-input__icon">
                                <span class="body-small-regular neutral-600"
                                    >Subtext</span
                                >
                            </span>
                        </el-input>

                        <el-row :gutter="20">
                            <el-col>
                                <p class="body-small-semi-bold">Login</p>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col
                                :xs="24"
                                :sm="24"
                                :md="24"
                                :lg="12"
                                :xl="12"
                            >
                                <el-form-item prop="password">
                                    <el-input
                                        placeholder="Please input"
                                        v-model="ruleForm.password"
                                        :type="
                                            ui.showPassword
                                                ? 'text'
                                                : 'password'
                                        "
                                    >
                                        <span
                                            slot="suffix"
                                            :class="
                                                ui.showPassword
                                                    ? 'icon-eye-on'
                                                    : 'icon-eye-off'
                                            "
                                            @click="
                                                ui.showPassword =
                                                    !ui.showPassword
                                            "
                                        ></span>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            textFilled: "Text đây nè",
            textarea1: "",
            input: "",

            ruleForm: {
                name: "",
                password: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "Error message",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Error message",
                        trigger: "blur",
                    },
                ],
            },
            ui: {
                showPassword: false,
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.el-row {
    margin: 24px 12px;
    display: flex;
    align-items: center;
}
</style>
<style lang="scss">
.el-input {
    .el-input__inner {
        height: 36px;
        padding: 8px 12px;
        color: var(--neutral-600);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        border-radius: 6px;

        border-color: var(--neutral-300);
        &:hover {
            border-color: var(--neutral-400);
        }
        &:focus {
            border-color: var(--primary-600);
        }
        &::placeholder {
            color: var(--neutral-400);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
}
.el-input__prefix {
    padding-left: 6px;
    height: -webkit-fill-available;
}
.el-input--prefix .el-input__inner {
    padding-left: 38px !important;
}
.el-input__suffix-inner {
    .el-input__icon {
        span {
            margin-left: 8px;
        }
    }
}
.el-input__suffix {
    display: flex;
    align-items: center;
}

/* Disbaled input */
.el-input.is-disabled {
    .el-input__inner {
        background: var(--neutral-50);
        color: var(--neutral-600);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
.el-input .el-input__clear {
    display: initial;
    padding-right: 6px;
    padding-left: 6px;
}

.el-transfer-panel__body {
    .el-input__prefix {
        height: 100%;
    }
}

.el-input--prefix {
    .el-input__inner {
        padding-left: 36px;
    }
}
.el-input--suffix {
    .el-input__inner {
        padding-right: 36px;
    }
}
.el-form-item {
    & > .el-form-item__label::before,
    & .el-form-item__label-wrap > .el-form-item__label::before {
        content: "";
        margin-left: 4px;
    }
}

.el-form-item.is-required {
    & > .el-form-item__label::after,
    & .el-form-item__label-wrap > .el-form-item__label::after {
        content: "*";
        color: var(--secondary-red-600);
        margin-left: 4px;
    }
}

.el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: "" !important;
    margin-left: 0 !important;
}

.el-form-item__error {
    word-break: break-word;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--secondary-red-600);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    &:before {
        background-image: url(~@/assets/images/svg/error.svg);
        background-size: 14px;
        background-repeat: no-repeat;
        display: inline-block;
        width: 14px;
        height: 14px;
        content: "";
    }
}
.el-form-item__label {
    display: flex;
}
</style>
